<template>
  <div class="upgrade-container">
    <Head />
    <div class="upgrade-content">
      <div class="tab-buttons">
        <button
          v-for="tab in ['Tap', 'Boost']"
          :key="tab"
          :class="['tab-button', { active: activeTab === tab }]"
          @click="setActiveTab(tab)"
        >
          {{ tab }}
        </button>
      </div>

      <div v-if="error" class="error-message">{{ error }}</div>
      <div v-if="loading" class="loading-message">Loading upgrades...</div>
      <div v-else-if="filteredUpgrades.length === 0" class="no-upgrades-message">No available upgrades</div>
      <div v-else class="upgrades-list">
        <div v-for="upgrade in filteredUpgrades" :key="upgrade.upgrade_id" class="upgrade-card">
          <div class="upgrade-info">
            <component :is="getIconComponent(upgrade.upgrade_name)" class="upgrade-icon" />
            <div class="upgrade-details">
              <div class="upgrade-title">{{ upgrade.upgrade_name }}</div>
              <div class="upgrade-level">Level: {{ upgrade.level }}</div>
            </div>
          </div>
          <button 
            class="upgrade-button" 
            :class="{ 'disabled': !canAfford(calculateCost(upgrade)) }"
            @click="openUpgradeModal(upgrade)"
            :disabled="!canAfford(calculateCost(upgrade))"
          >
            {{ calculateCost(upgrade) }}
          </button>
        </div>
      </div>
    </div>
    <NavBar />

    <!-- Модальное окно -->
    <div v-if="showModal" class="modal-overlay">
      <div class="modal-content">
        <button class="close-button" @click="closeModal">&times;</button>
        <h2>Upgrade {{ selectedUpgrade.upgrade_name }}</h2>
        <p>Are you sure you want to upgrade {{ selectedUpgrade.upgrade_name }} to level {{ selectedUpgrade.level + 1 }}?</p>
        <p>Cost: {{ calculateCost(selectedUpgrade) }}</p>
        <button class="upgrade-confirm-button" @click="confirmUpgrade">Upgrade</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import Head from './Head.vue'
import NavBar from './NavBar.vue'
import { Zap, Battery, RefreshCw, Crop, Database, Clock, Percent } from 'lucide-vue-next'
import axios from 'axios'

export default {
  name: 'Upgrade',
  components: {
    Head,
    NavBar,
    Zap,
    Battery,
    RefreshCw,
    Crop,
    Database,
    Clock,
    Percent
  },
  setup() {
    const store = useStore()
    const activeTab = ref('Tap')
    const showModal = ref(false)
    const selectedUpgrade = ref(null)
    const upgrades = ref([])
    const loading = ref(true)
    const error = ref(null)

    const loadUpgrades = async () => {
      try {
        loading.value = true
        error.value = null
        const response = await axios.get('/upgrades', {
          params: { user_id: store.state.userData.user_id }
        })
        upgrades.value = response.data.upgrades.map(upgrade => ({
          ...upgrade,
          level: upgrade.level || 1  // Убедитесь, что уровень по умолчанию 1
        }))
      } catch (err) {
        console.error('Ошибка при загрузке улучшений:', err)
        error.value = 'Не удалось загрузить улучшения. Пожалуйста, попробуйте позже.'
      } finally {
        loading.value = false
      }
    }

    onMounted(loadUpgrades)

    const setActiveTab = (tab) => {
      activeTab.value = tab
    }

    const filteredUpgrades = computed(() => {
      return upgrades.value.filter(upgrade => upgrade.upgrade_tape === activeTab.value)
    })

    const getIconComponent = (upgradeName) => {
      const iconMap = {
        'Token Tapper': Zap,
        'Fast Recharge': RefreshCw,
        'Energy Boost': Battery,
        'Farming Accelerator': Crop,
        'Storage Expansion': Database,
        'Extended Boost': Clock,
        'Power Boost': Percent
      }
      return iconMap[upgradeName] || Zap
    }

    const calculateCost = (upgrade) => {
      return Math.round(upgrade.base_cost * Math.pow(upgrade.cost_multiplier, upgrade.level - 1))
    }

    const canAfford = (cost) => {
      return store.getters.getBalance >= cost
    }

    const openUpgradeModal = (upgrade) => {
      selectedUpgrade.value = upgrade
      showModal.value = true
    }

    const closeModal = () => {
      showModal.value = false
      selectedUpgrade.value = null
    }

    const confirmUpgrade = async () => {
      try {
        const upgradeCost = calculateCost(selectedUpgrade.value)
        if (store.getters.getBalance >= upgradeCost) {
          // Отправляем запрос на сервер для обновления данных в БД
          const response = await axios.post('/upgrade', {
            user_id: store.state.userId,
            upgrade_id: selectedUpgrade.value.upgrade_id
          })
          if (response.error) {
            closeModal()
            error.value = response.error
            return;
          }
          // Обновляем локальные данные
          store.commit('decrementBalance', upgradeCost)
          store.commit('incrementUpgradeLevel', selectedUpgrade.value.upgrade_id)

          // Обновляем соответствующие значения в зависимости от типа улучшения
          switch (selectedUpgrade.value.upgrade_name) {
            case 'Token Tapper':
              store.commit('incrementTokenPerTap', 1)
              break
            case 'Fast Recharge':
              store.commit('incrementClickRecoverPerSec', 1)
              break
            case 'Energy Boost':
              store.commit('incrementAvailableMax', 500)
              break
            case 'Farming Accelerator':
              store.commit('incrementTokenPerHour', 1000)
              break
            // Для остальных улучшений пока ничего не делаем
          }


          // Закрываем модальное окно
          closeModal()

          // Перезагружаем улучшения
          await loadUpgrades()
        } else {
          error.value = 'Недостаточно средств для улучшения'
        }
      } catch (err) {
        console.error('Ошибка при подтверждении улучшения:', err)
        error.value = 'Произошла ошибка при выполнении улучшения'
      }
    }

    return {
      activeTab,
      setActiveTab,
      filteredUpgrades,
      getIconComponent,
      calculateCost,
      canAfford,
      showModal,
      selectedUpgrade,
      openUpgradeModal,
      closeModal,
      confirmUpgrade,
      loading,
      error
    }
  }
}
</script>

<style scoped>
.upgrade-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #c9aa71;
  color: black;
}

.upgrade-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-top: 90px;
  padding-bottom: 60px;
}

.tab-buttons {
  display: flex;
  background-color: #4b5563;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.tab-button {
  flex: 1;
  padding: 0.5rem;
  text-align: center;
  color: #d1d5db;
  background: none;
  border: none;
  cursor: pointer;
}

.tab-button.active {
  background-color: #4CAF50;
  color: white;
  border-radius: 0.5rem;
}

.upgrades-list {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-bottom: 1rem;
}

.upgrades-list::-webkit-scrollbar {
  display: none;
}

.upgrade-card {
  background-color: white;
  padding: 0.75rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upgrade-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.upgrade-icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #4CAF50;
}

.upgrade-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.upgrade-title {
  font-size: 0.875rem;
  font-weight: 600;
}

.upgrade-level {
  font-size: 0.75rem;
  color: #6b7280;
}

.upgrade-button {
  width: 80px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  transition: background-color 0.3s;
}

.upgrade-button:hover {
  background-color: #45a049;
}

.upgrade-button.disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 400px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.upgrade-confirm-button {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
}

.upgrade-confirm-button:hover {
  background-color: #45a049;
}

.loading-message, .error-message, .no-upgrades-message {
  text-align: center;
  padding: 20px;
  font-size: 18px;
}

.error-message {
  color: red;
}
</style>