<template>
  <div class="floating-number" :style="{ top: y + '%', left: x + '%' }">
    +{{ amount }}
  </div>
</template>

<script>
export default {
  name: 'FloatingNumber',
  props: {
    x: {
      type: Number,
      required: true
    },
    y: {
      type: Number,
      required: true
    },
    amount: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped>
.floating-number {
  position: absolute;
  font-size: 24px;
  font-weight: bold;
  color: red;
  animation: float 1s ease-out;
}

@keyframes float {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}
</style>