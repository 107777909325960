export const globalSettings = {
    domain: "skuff-empire.com",
    adsgramId: "4826",
    telegramBot: "skuffempbot",
    onlyMobile: true,
    items: [
        { id: 1, name: "Item 1" },
        { id: 2, name: "Item 2" },
        { id: 3, name: "Item 3" },
    ],
};